import React from 'react'
import styled from 'styled-components'
import { useFlexSearch } from 'react-use-flexsearch'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'
import parse from 'html-react-parser'

// Elements
import ParseContent from 'components/shared/ParseContent'
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'

const Wrapper = styled.section``

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 20px;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

const Input = styled.input`
  z-index: 2;
  position: relative;
  padding: 20px 25px;
  height: 35px;
  width: 300px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.light};

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`

const SearchResult = styled(motion.div)`
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  margin: 20px 0 20px 0;
  background-color: ${({ theme }) => theme.color.light};
`

const Type = styled.p`
  position: relative;
  font-weight: bold;

  &::before {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`

interface SearchEngineProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_SearchEngine
  location?: any
  className?: string
}

const SearchEngine: React.FC<SearchEngineProps> = ({ fields }) => {
  const { posts, pages, projects } =
    useStaticQuery<GatsbyTypes.searchQueryQuery>(graphql`
      query searchQuery {
        posts: localSearchPosts {
          index
          store
        }
        pages: localSearchPages {
          index
          store
        }
        projects: localSearchProjects {
          index
          store
        }
      }
    `)

  const [query, setQuery] = React.useState<string | null>('')

  const pageResults = useFlexSearch(query, pages?.index, pages?.store)

  const postResults = useFlexSearch(query, posts?.index, posts?.store)

  const projectsResults = useFlexSearch(query, projects?.index, projects?.store)

  function handleSearchChange(e: any) {
    setQuery(e.target.value)
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = urlParams.get('query')

    setQuery(params)
  }, [])

  return (
    <Wrapper className="container">
      <div className="row mb-5 pb-lg-5 justify-content-center">
        <div className="col-lg-4">
          <Content content={fields.description} />
          <Input
            type="search"
            className=""
            value={query || ''}
            onChange={handleSearchChange}
            placeholder="Zoeken"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        {query !== null &&
          projectsResults.map((r: any, index: number) => (
            <div
              className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <SearchResult
                initial={{ y: 50, opacity: 0.5 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  type: 'spring',
                }}
                className="p-4 w-100 h-100 d-flex flex-column"
              >
                <div className="d-flex justify-content-start">
                  <Type>{r.type}</Type>
                </div>
                <h3>{parse(r.title)}</h3>
                <div className="d-flex justify-content-center mt-auto">
                  <AnchorPrimary href={r.uri}>Ga naar resultaat</AnchorPrimary>
                </div>
              </SearchResult>
            </div>
          ))}
        {query !== null &&
          pageResults.map((r: any, index: number) => (
            <div
              className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <SearchResult
                initial={{ y: 50, opacity: 0.5 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  type: 'spring',
                }}
                className="p-4 w-100 h-100 d-flex flex-column"
              >
                <div className="d-flex justify-content-start">
                  <Type>{r.type}</Type>
                </div>
                <h3>{parse(r.title)}</h3>
                <div className="d-flex justify-content-center mt-auto">
                  <AnchorPrimary href={r.uri}>Ga naar resultaat</AnchorPrimary>
                </div>
              </SearchResult>
            </div>
          ))}
        {query !== null &&
          postResults.map((r: any, index: number) => (
            <div
              className="col-lg-4 col-sm-6 d-flex flex-grow-1 mb-5"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <SearchResult
                initial={{ y: 50, opacity: 0.5 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  type: 'spring',
                }}
                className="p-4 w-100 h-100 d-flex flex-column"
              >
                <div>
                  <div className="d-flex justify-content-start">
                    <Type>{r.type}</Type>
                  </div>
                  <h3>{parse(r.title)}</h3>
                </div>

                <div className="d-flex justify-content-center mt-auto">
                  <AnchorPrimary href={r.uri}>Ga naar resultaat</AnchorPrimary>
                </div>
              </SearchResult>
            </div>
          ))}
      </div>
    </Wrapper>
  )
}

export default SearchEngine
